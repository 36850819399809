
import { BoardDataProvider } from '@/components/Table/Board/BoardDataProvider';
import { closedBoardBoardTableConfigBuilder } from '@/components/Table/Board/ClosedBoardTableConfigBuilder';
import { BoardTableData } from '@/components/Table/Board/BoardTableData';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import { boardTitleFilter } from '@/components/Table/Board/BoardFilters';

@Component<A3ClosedBoardCollectionPage>({
  components: {
    LegacyTableComponent,
    AsyncTableComponent
  }
})
export default class A3ClosedBoardCollectionPage extends Vue {
  @Prop({
    default: () => []
  })
  boards!: Board[];

  boardSearchService = new TableSearchService<
    BoardTableData,
    Board,
    never,
    SearchBoardDto
  >(
    this.$store,
    new BoardDataProvider(this.$store),
    {
      isClosed: true
    },
    { defaultSortProperty: 'meetingDate' },
    closedBoardBoardTableConfigBuilder,
    {
      filters: [boardTitleFilter],
      quickFilters: []
    }
  );

  mounted() {
    this.boardSearchService.queryData();
  }
}
