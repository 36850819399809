import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { stringFilterComponentDataBuilder } from '@/models/Table/Table';

/**
 * Filters go into the filter dropdown and usually have inputs to define the filter value
 * @param string the label showing in the drop down options
 */
export const boardTitleFilter = stringFilterComponentDataBuilder(
  'Board Title',
  (t: SearchBoardDto, value: string) => {
    t.title = value;
  }
);

// WON't DO filter by dates

// 'numberOfApplications' is a relation. NO DO
