var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "relative w-full h-full p-4" },
    [
      _c("h3", { staticClass: "pt-4" }, [_vm._v("Closed Boards")]),
      _c(
        "async-table-component",
        _vm._g(
          _vm._b(
            {
              staticClass: "mt-8",
              attrs: {
                isSortable: true,
                isColumnEditable: true,
                isFilterable: true,
                emptyTableMessage: "0 boards",
                localStorageKey: "A3-closed-board-collection-table",
              },
            },
            "async-table-component",
            _vm.boardSearchService.tableProps,
            false
          ),
          _vm.boardSearchService.tableEventListeners
        ),
        [_vm._v(" >")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }