import { Board } from '@/models/Entities/Board';
import { IBaseBoardTableData } from './BaseBoardTableData';

export class BoardTableData implements IBaseBoardTableData {
  id!: number;
  entityId: number;
  title!: string;
  meetingDate!: Date;
  startDate!: Date;
  endDate!: Date;
  numberOfApplications: number;
  isActive!: boolean;
  isArchived!: boolean;

  constructor(board: Board) {
    this.id = board.id;
    this.entityId = board.id;
    this.title = board.title;
    this.meetingDate = board.meetingDate;
    this.startDate = board.startDate;
    this.endDate = board.meetingDate;
    this.numberOfApplications = board.applicationIds.length;
    this.isActive = board.isActive;
    this.isArchived = board.isArchived;
  }
}
