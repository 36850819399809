import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { TableDataProvider } from '../models/TableDataProvider';
import { BoardTableData } from './BoardTableData';

export class BoardDataProvider extends TableDataProvider<
  BoardTableData,
  Board,
  SearchBoardDto
> {
  protected transformer = (t: Board): BoardTableData => new BoardTableData(t);

  protected queryHandler = async (
    searchDto: SearchBoardDto
  ): Promise<SearchResponseDto<Board>> => {
    const results: SearchResponseDto<Board> = await this.store.dispatch(
      'boardModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}
