import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { Board } from '@/models/Entities/Board';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '../Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '../models/TableConfigBuilder';
import { BoardTableData } from './BoardTableData';

export const closedBoardBoardTableConfigBuilder: TableConfigBuilder<
  BoardTableData,
  Board,
  never,
  SearchBoardDto
> = (): ITableConfiguration<BoardTableData, Board, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Board Title',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: BoardTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.title,
              linkTo: {
                name: Routes.A3_BOARD,
                params: { id: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'title',
        defaultPinned: true
      },
      {
        columnTitle: 'Board Meeting Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: BoardTableData) => {
            return {
              date: rowData.meetingDate
            };
          }
        },
        sortKey: 'meetingDate'
      },
      {
        columnTitle: 'Board Open Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: BoardTableData) => {
            return {
              date: rowData.startDate
            };
          }
        },
        sortKey: 'startDate'
      },
      {
        columnTitle: 'Board Closed Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: BoardTableData) => {
            return {
              date: rowData.endDate
            };
          }
        },
        sortKey: 'endDate'
      },
      {
        columnTitle: 'Number of Applications',
        render: 'numberOfApplications'
        // sortKey: 'numberOfApplications' is a relation. WON'T DO
      }
    ]
  };
};
